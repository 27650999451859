//
// Base
//



// Body
body {
	// background: $kt-content-bg-color;
	background: #fff;
}

tr.no-wrap td {
    white-space: nowrap;
}

td.categories span,
span.gray-bg
{
    padding: 5px 7px;
    margin: 0 2px;
    background: rgb(231, 239, 243);
    border-radius: 18px;
}

.form-border-group {
    border: 1px solid;
    border-radius: 5px;
    padding: 15px 10px 10px;
}

span.gray-bg {
    font-size: 0.6em;
}

.link {
    cursor: pointer;
}

.di-popup-inner {
    width: 339px;
    // height: 425px;
	height:auto;
    font-size: 17px;
    overflow-y: auto;
    padding: 20px;
}
.di-popup-basic-inner {
	width: 339px;
	// height: 425px;
	height: auto;
	font-size: 17px;
	overflow-y: auto;
	padding: 10px 14px;
}

// Base
.kt-subheader {
	&.kt-subheader--clear {
		margin-bottom: 0 !important;
	}
}

.fw-bold {
	font-weight:bold;
}
.kt-menu__item.kt-menu__item--submenu.fw-bold a.kt-menu__link span.kt-menu__link-text {
	font-weight:600;
}

.kt-menu__section.tools-checklist-button .kt-menu__section-text,
.kt-menu__section.gmc-checklist-button .kt-menu__section-text,
.kt-menu__section.sop-checklist-button .kt-menu__section-text {
	cursor:pointer;
}

.kt-aside-menu .kt-menu__nav .kt-menu__item.tools-checklist-dropdown,
.kt-aside-menu .kt-menu__nav .kt-menu__item.gmc-checklist-dropdown,
.kt-aside-menu .kt-menu__nav .kt-menu__item.sop-checklist-dropdown {
	display:none;
}




.TmoProgressBar.progressBar {
	height:24px;
	margin-bottom:40px;
	margin-top:18px;
	border-radius:0;
}
.TmoProgressBar.progressBar .progress-bar {
	font-size:20px;
	// padding-right:20px;
	text-align: right;
	border-radius:0;
	overflow: visible;
}
.TmoProgressBar.progressBar .progress-bar .progress-label {
	padding-right:20px;
	font-size:11px;
	padding-left:10px;
}

.TmoProgressBar.progressBar .first_label {
	position: absolute;
	bottom:30px;
	font-size:11px;
}

.TmoProgressBar.progressBar .second_label {
	position: absolute;
	bottom:30px;
	left:calc(10% + 36px);
	font-size:11px;
}
.TmoProgressBar.progressBar .second_label .vertical_line {
	position: relative;
	top:-14px;
	text-align: center;
}

.TmoProgressBar.progressBar .third_label {
	position: absolute;
	bottom:30px;
	left:calc(50% + 40px);
	font-size:11px;
}
.TmoProgressBar.progressBar .third_label .vertical_line {
	position: relative;
	top:-14px;
	text-align: center;
}

.TmoProgressBar.progressBar .fourth_label {
	position: absolute;
	bottom:30px;
	left:calc(100% - 49px);
	font-size:11px;
}
.TmoProgressBar.progressBar .fourth_label .vertical_line {
	position: relative;
	top:-14px;
	text-align: right;
}

.progressBar {
	// background:#ccd1f4;
	background:rgba(44, 119, 244, 0.1);
}
.TmoProgressBar.progressBar .progress-bar {
	background:#2c77f4;
}

b, strong {
	font-weight:600 !important;
}
.mb-1, .my-1 {
	margin-bottom:0.4rem!important;
}

// START -> SEO AUDITOR TOOL
#so-form {
	margin: 10px!important;
}
#so-fieldswrapso1675785243 {
	width: 300px!important;
}
.so-fieldso1675785243 {
	background-color: #FFFFFF!important;
	border: 1px solid #e3e3e3!important;
	border-radius: 0px !important;
	color: #565656!important;
	padding: 7px 12px!important;
	font-size: 18px!important;
	box-sizing: border-box !important;
	height: 45px!important;
	width: 100%!important;
	display: block!important;
	margin-top: 5px!important;
}
.so-fieldso1675785243::placeholder {
	color: #777777!important;
}

@media screen and (max-width: 345px){
	.so-fieldso1675785243 {
		min-width: 100px!important;
	}
}

@media screen and (max-width: 500px){
	.so-fieldso1675785243 {
		font-size: 12px !important;
		height: 30px !important;
	}

	#so-submitso1675785243 {
		font-size: 12px !important;
		height: 30px;
	}

	#so-fieldswrapso1675785243 {
		width: 100%;
	}

}

#global-error-alert {
    position: fixed;
    top: 80px;
    right: 20px;
    padding: 15px 30px;
    background-color: #333;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
    transition: opacity 0.5s, transform 0.5s, visibility 0.5s;
    z-index: 2000; /* Ensure it appears above other content */
}

#so-submitso1675785243 {
	background-color: #ffbd3c!important;
	border: 1px solid #ffbd3c!important;
	color: #000000!important;
	border-radius: 0px !important;
	text-decoration: none!important;
	cursor: pointer!important;
	box-sizing: border-box !important;
	height: 45px!important;
	font-size: 18px!important;
line-height: 18px!important;
	display: block!important;
	padding: 8px 50px!important;
	margin: 5px auto!important;
}


@media screen and (max-width: 500px){
	#so-submitso1675785243 {
		font-size: 12px!important;
		height: 30px!important;
	}
}

.so-container {
	width: 100%;
	text-align: center;
}
.so-widget-form {
	display: inline-block !important;
}
#so-boxso1675785243 {
	display: inline-block;
}
#so-titleso1675785243 {
	margin-bottom: 5px;
	font-size: 28px !important;
	color: #ffffff !important;
}

@media screen and (max-width: 500px){
	#so-titleso1675785243 {
		font-size: 13px !important;
	}

	#so-paragraphso1675785243 {
		font-size: 10px !important;
	}

	#so-consentso1675785243 span {
		font-size: 10px !important;
	}
}

#so-paragraphso1675785243 {
	margin-bottom: 10px;
	font-size: 14px !important;
	color: #777777 !important;
}
#so-boxso1675785243 {
	width: 100%;
	padding: 50px !important;
	background-color: #2c5dbf !important;
	border: 0px solid #e3e3e3 !important;
}
@media screen and (max-width: 1039px){
	#so-boxso1675785243 {
		padding: 50px 10px !important;
		background-color: #2c5dbf !important;
		border: 0px solid #e3e3e3 !important;
	}
}
@media screen and (max-width: 1039px){
	#so-boxso1675785243 {
		padding: 25px 5px !important
	}
}
// END -> SEO AUDITOR TOOL

// Desktop mode
@include kt-desktop {
	// Page
	.kt-wrapper {
		transition: kt-get($kt-aside-config, base, minimize, transition);

		// Fixed Header
		.kt-header--fixed & {
			padding-top: kt-get($kt-header-config, base, desktop, default, height);
		}

		// Fixed Content Head
		.kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled & {
			padding-top: kt-get($kt-header-config, base, desktop, default, height) + kt-get($kt-subheader-config, fixed, height);
		}

		// Fixed Aside
		.kt-aside--fixed & {
            padding-left: calc(#{kt-get($kt-aside-config, base, default, width)} + 15px);
		}

		// Minimize Aside
		.kt-aside--fixed.kt-aside--minimize & {
			padding-left: kt-get($kt-aside-config, base, minimize, width);
			transition: kt-get($kt-aside-config, base, minimize, transition);
		}
	}

	// Subheader
	.kt-subheader {
		// Fixed subheader mode
		.kt-header--fixed.kt-subheader--fixed & {
			position: fixed;
			height: kt-get($kt-subheader-config, fixed, height);
			top: kt-get($kt-header-config, base, desktop, default, height);
			left: 0;
			right: 0;
			transition: all 0.3s ease;
			z-index: kt-get($kt-header-config, base, desktop, fixed, zindex) - 3;
			background-color: #fff;
			box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.08);
			margin: 0;
		}

		// Fixed header, minimized header and fixed subheader
		.kt-header--fixed.kt-header--minimize.kt-subheader--fixed & {
			top: kt-get($kt-header-config, base, desktop, fixed, minimizeHeight);
			transition: top 0.3s ease;
		}

		.kt-aside--enabled.kt-subheader--fixed & {
			left: kt-get($kt-aside-config, base, default, width);
		}

		.kt-subheader--fixed.kt-aside--minimize-hover &,
		.kt-subheader--fixed.kt-aside--minimize & {
			left: kt-get($kt-aside-config, base, minimize, width);
			transition: kt-get($kt-aside-config, base, minimize, transition);
		}
	}

	// Container
	.kt-container {
		padding: 0 kt-get($kt-page-padding, desktop);
	}

	// Content & fixed subheader mode
	.kt-subheader--enabled:not(.kt-subheader--fixed) {
		.kt-content {
			padding-top: 0;
		}
	}
}

// Fixed container width change to fluid
@include kt-media-range( lg, $kt-page-container-width + $kt-aside-default-width + (2 * kt-get($kt-page-padding, desktop)) ) {
	.kt-container:not(.kt-container--fluid) {
		width: 100%;
	}
}

// Tablet & mobile mode
@include kt-tablet-and-mobile {
	// Page
	.kt-wrapper {
        margin-bottom: 80px;

		// Fixed Header
		.kt-header-mobile--fixed & {
			padding-top: kt-get($kt-header-config, base, mobile, self, default, height);
		}
	}

	// Container
	.kt-container {
		padding: 0 kt-get($kt-page-padding, mobile);
	}

	// Content & fixed subheader mode
	.kt-subheader--enabled {
		.kt-content {
			padding-top: 0;
		}
	}

	.kt-subheader--enabled.kt-subheader--solid {
		.kt-subheader {
			margin-bottom: kt-get($kt-page-padding, mobile);
		}
	}

	.marketplace_right_green {
        margin-left:0px;
        margin-top:20px;
    }
	.marketplace_left_blue {
		margin-right:0px;
	}
}

.btn {
	padding-top:0.5rem;
	padding-bottom:0.5rem;
}

@media (max-width: 1115px) {
	.topbar-my-sales, .topbar-my-orders {
		display:none;
	}
}


.tags-input {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 48px;
	padding: 0 8px;
	border: 1px solid rgb(214, 216, 218);
	border-radius: 6px;
	&:focus-within {
		border: 1px solid #E6E6E6;
	}
	input {
		flex: 1;
		border: none;
		height: 46px;
		font-size: 14px;
		padding: 4px 0 0 0;
		&:focus {
			outline: transparent;
		}
	}
}

#tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 8px 0 0 0;
}

.tag {
	width: auto;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 6px;
	margin: 0 8px 8px 0;
	background: #E6E6E6;
	.tag-title {
		margin-top: 3px;
	}
	.tag-close-icon {
		display: block;
		width: 16px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		font-size: 14px;
		margin-left: 8px;
		color: #E6E6E6;
		border-radius: 50%;
		background: #000;
		cursor: pointer;
	}
}

@media screen and (max-width: 567px) {
	.tags-input {
		width: calc(100vw - 32px);
	}
}
