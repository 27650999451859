.custom-header {
    font-size:24px;
    color: #48465b;
    font-weight:500;
}
.custom-header.gray {
    color: #646c9a;
}
.projects-block {
    min-width: 640px;
}
@media (max-width: 767px) {
    .projects-block {
        min-width: 100%;
    }
}
@media (max-width: 575px) {
    .projects-block {
        min-width: unset;
    }
}
