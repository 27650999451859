.analytics {
    table#analyticsTaskManager {
        tbody tr td:not(:last-child) {
            padding: 0.5rem;
        }
    }
    .metric-wrap {
        padding: 20px;
        margin-bottom: 10px;
        border: 1px solid rgba(131, 125, 209, 0.1);
        border-radius: 4px;
        .metric-head {
            padding: 1rem 0;
        }
        .metric-body {

            .metric-data {

                .metric-info {

                }
                .metric-label {
                    font-size: 4rem;
                    line-height: 4rem;
                    text-align: left;
                    display: block;
                    padding: 1rem 0 0 0;
                    font-weight: 800;
                    color: #5d5e71;
                }
                .m-invoice__items {
                    display: table;
                    width: 100%;
                    padding: 5rem 0 6rem 0;
                    table-layout: fixed;
                    border-top: 1px solid #837dd1;
                    .m-invoice__item {
                        display: table-cell;
                        color: #fff;
                        .m-invoice__subtitle {
                            font-weight: 500;
                            padding-bottom: 0.5rem;
                        }
                        > span {
                            display: block;
                        }
                        .m-invoice__text {
                            color: #c3c1e9;
                        }
                    }
                }
            }
            .metric-description {
                padding-bottom: 10px;
            }
            .growth-prev-period {
                min-height: 32px;
                .arrow {
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    font-size: 22px;
                    font-weight: 800;
                    /*border: 1px solid #dedede;
                    border-radius: 2px;*/
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
                }
                .arrow.up {
                    transform: rotate(-90deg);
                    /*background-color: rgba(10, 187, 135 , 0.4);*/
                    color: rgb(10, 187, 135);
                }
                .arrow.down {
                    transform: rotate(90deg);
                    /*background-color: rgba(157, 0, 6 , 0.4);*/
                    color: rgb(157, 0, 6);
                }
                .result-text {
                    padding-left: 10px;
                    font-size: 0.8em;
                    line-height: 1.1em;
                    > span.up {
                        color: rgb(10, 187, 135);
                    }
                    > span.down {
                        color: rgb(157, 0, 6);
                    }
                }
            }
        }

    }
    .popover-tooltip{
        border-radius: 5px;
        box-shadow: 2px 2px 6px -4px #999;
        cursor: default;
        font-size: 14px;
        left: 62px;
        opacity: 1;
        pointer-events: none;
        position: unset;
        top: 20px;
        overflow: hidden;
        white-space: nowrap;
        z-index: 12;
        transition: 0.15s ease all;
    }
    .apexcharts-annotations:hover{
        cursor: pointer;
    }
    .apexcharts-marker:hover{
        cursor: pointer;
    }
    .apexcharts-tooltip-z-group.google-update-note{
        border-left: 2px solid rgba(119, 93, 208, 0.3);
        padding-left: 5px;
        margin-top: 5px;
    }
    .apexcharts-tooltip-z-group.custome-note {
        border-left: 2px solid rgba(255, 69, 96, 0.3);
        padding-left: 5px;
        margin-top: 5px;
    }
    .apexcharts-tooltip-text-note-value {
       line-height: 16px;
    }

}
