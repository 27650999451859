.service-tabs {
    .nav-pills {
        .nav-item {
            .nav-link {
                font-size: 18px;

                &:active,&.active,&.active:hover {
                    background-color: transparent;
                    color: #2c77f4;
                }
            }

            h1 {
                font-size: 22px;
                font-weight: 600;
                text-transform: uppercase;
                color:#46607d;
                padding: 0.75rem 1.25rem;
                margin: 0;
            }
        }
    }

    .card {
        box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);

        .card-text {
            margin-bottom: 75px;
        }

        .cl-container {
            position: absolute;
            left:0;
            bottom:0;
            width: 100%;
            text-align: center;
            padding-bottom: 20px;
        }
    }

    .tab-content {
        background-color: transparent;
    }
}
