.datatable {
    overflow:auto;
    &:not(.table) {
        display: none;
    }
}
.datatable.datatable-default {
    display: block;
    >.datatable-table {
        background-color: #fff;
        border-collapse: collapse;
        overflow: hidden;
        display: none;
        width: 100%;
        >.datatable-body {
            visibility: hidden;
            //display: block;
            .datatable-row {
                width: 100%;
                >.datatable-cell {
                    vertical-align: middle;
                    padding: 1rem 1rem;
                    font-size: 1rem;
                    font-weight: 400;
                    -webkit-transition: background .3s ease;
                    transition: background .3s ease;
                    border: none;
                    font-family: Poppins,Helvetica,sans-serif;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    >span {
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #464e5f;
                    }
                    .dropdown {
                        display: inline-block;
                        .dropdown-menu {
                            margin-top: .5rem;
                        }
                        .dropdown-menu.dropdown-menu-right {
                            margin-right: -.8rem;
                            margin-left: 65px;
                        }
                        .dropdown-menu.dropdown-menu-left {
                            margin-left: -10rem;
                        }
                    }
                    .dropdown.dropup {
                        .dropdown-menu {
                            margin-top: auto;
                            margin-bottom: .5rem;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-left {
                    text-align: left;
                    >span {
                        text-align: left;
                    }
                }
                >.datatable-cell.datatable-cell-right {
                    text-align: right;
                    >span {
                        text-align: right;
                        >i {
                            right: 0;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-center {
                    text-align: center;
                    >span {
                        text-align: center;
                    }
                }
                >.datatable-cell.datatable-cell-sort {
                    cursor: pointer;
                    i {
                        font-size: .6rem;
                    }
                }
                >.datatable-cell.datatable-cell-resizing {
                    cursor: col-resize;
                }
                >.datatable-cell.datatable-cell-check {
                    text-align: left;
                    >span {
                        overflow: visible;
                        >.checkbox {
                            top: 2px;
                            padding: 0;
                            margin: 0;
                            top: 1px;
                        }
                    }
                }
                >.datatable-cell.datatable-toggle-detail {
                    >span {
                        width: 12px;
                    }
                    >.datatable-toggle-detail {
                        display: inline-block;
                        text-align: right;
                        >i {
                            font-size: 1.4rem;
                            width: 12px;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-hover {
                    -webkit-transition: background .3s ease;
                    transition: background .3s ease;
                    background-color: transparent;
                }
            }
            >.datatable-lock {
                border: 0;
            }
            >.datatable-lock.datatable-lock-left {
                z-index: 1;
                -webkit-box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
                box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
            }
            >.datatable-lock.datatable-lock-right {
                -webkit-box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
                box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
            }
            >.datatable-lock.datatable-lock-scroll {
                position: relative;
            }
            .datatable-row.datatable-row-even {
                >.datatable-cell {
                    background-color: transparent;
                }
            }
            .datatable-row.datatable-row-active {
                >.datatable-cell {
                    background-color: #f3f6f9;
                }
            }
            .datatable-row.datatable-row-hover {
                &:not(.datatable-row-active) {
                    -webkit-transition: background .3s ease;
                    transition: background .3s ease;
                    >.datatable-cell {
                        -webkit-transition: background .3s ease;
                        transition: background .3s ease;
                        background-color: transparent;
                    }
                }
            }
            .datatable-row-detail {
                display: block;
                .datatable-detail {
                    display: block;
                    padding: 0 2.25rem;
                    table {
                        -webkit-box-shadow: 0 10px 15px 1px rgba(0,0,0,.05);
                        box-shadow: 0 10px 15px 1px rgba(0,0,0,.05);
                    }
                    .datatable-row {
                        display: table-row;
                        >.datatable-cell {
                            padding: 1rem 1.5rem;
                            text-align: left;
                            vertical-align: top;
                            >span {
                                width: auto !important;
                            }
                            &:first-child {
                                font-weight: 500;
                            }
                            &:last-child {
                                font-weight: regular;
                                padding-left: 1.5rem;
                            }
                        }
                    }
                }
            }
            .datatable-toggle-detail {
                vertical-align: middle;
                i {
                    -webkit-transition: color .3s ease;
                    transition: color .3s ease;
                    color: #1bc5bd;
                    &:before {
                        line-height: 0;
                        vertical-align: middle;
                    }
                }
                &:hover {
                    i {
                        -webkit-transition: color .3s ease;
                        transition: color .3s ease;
                        color: #1bc5bd;
                    }
                }
            }
            .datatable-toggle-detail.datatable-toggle-detail-active {
                i {
                    -webkit-transition: color .3s ease;
                    transition: color .3s ease;
                    color: #1bc5bd;
                }
            }
        }
        >.datatable-foot {
            visibility: hidden;
            display: block;
            .datatable-row {
                width: 100%;
                >.datatable-cell {
                    vertical-align: middle;
                    padding: 1rem 1rem;
                    font-size: 1rem;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    >span {
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-weight: 500;
                        vertical-align: middle;
                        color: #212121;
                    }
                    .dropdown {
                        display: inline-block;
                        .dropdown-menu {
                            margin-top: .5rem;
                        }
                        .dropdown-menu.dropdown-menu-right {
                            margin-right: -.8rem;
                            margin-left: 65px;
                        }
                        .dropdown-menu.dropdown-menu-left {
                            margin-left: -10rem;
                        }
                    }
                    .dropdown.dropup {
                        .dropdown-menu {
                            margin-top: auto;
                            margin-bottom: .5rem;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-left {
                    text-align: left;
                    >span {
                        text-align: left;
                    }
                }
                >.datatable-cell.datatable-cell-right {
                    text-align: right;
                    >span {
                        text-align: right;
                        >i {
                            right: 0;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-center {
                    text-align: center;
                    >span {
                        text-align: center;
                    }
                }
                >.datatable-cell.datatable-cell-sort {
                    cursor: pointer;
                    i {
                        font-size: .6rem;
                    }
                }
                >.datatable-cell.datatable-cell-resizing {
                    cursor: col-resize;
                }
                >.datatable-cell.datatable-cell-check {
                    text-align: left;
                    >span {
                        overflow: visible;
                        >.checkbox {
                            top: 2px;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
                >.datatable-cell.datatable-toggle-detail {
                    >span {
                        width: 12px;
                    }
                    >.datatable-toggle-detail {
                        display: inline-block;
                        text-align: right;
                        >i {
                            font-size: 1.4rem;
                            width: 12px;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-sorted {
                    >span {
                        color: #1bc5bd;
                    }
                }
            }
            >.datatable-lock {
                border: 0;
            }
            >.datatable-lock.datatable-lock-left {
                z-index: 1;
                -webkit-box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
                box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
            }
            >.datatable-lock.datatable-lock-right {
                -webkit-box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
                box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
            }
            >.datatable-lock.datatable-lock-scroll {
                position: relative;
            }
        }
        >.datatable-head {
            visibility: hidden;
            //display: block;
            .datatable-row {
                width: 100%;
                >.datatable-cell {
                    vertical-align: middle;
                    padding: 1rem 1rem;
                    font-size: 1rem;
                    padding: .75rem 1rem;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    >span {
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-weight: 500;
                        vertical-align: middle;
                        color: #212121;
                        >i {
                            display: inline-block;
                            /*position: relative;
                            right: -10px;*/
                            margin-left: 5px;
                            line-height: 0;
                            vertical-align: middle;
                            font-size: 1.1rem;
                            color: #1bc5bd;
                        }
                    }
                    .dropdown {
                        display: inline-block;
                        .dropdown-menu {
                            margin-top: .5rem;
                        }
                        .dropdown-menu.dropdown-menu-right {
                            margin-right: -.8rem;
                            margin-left: 65px;
                        }
                        .dropdown-menu.dropdown-menu-left {
                            margin-left: -10rem;
                        }
                    }
                    .dropdown.dropup {
                        .dropdown-menu {
                            margin-top: auto;
                            margin-bottom: .5rem;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-left {
                    text-align: left;
                    >span {
                        text-align: left;
                    }
                }
                >.datatable-cell.datatable-cell-right {
                    text-align: right;
                    >span {
                        text-align: right;
                        >i {
                            right: 0;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-center {
                    text-align: center;
                    >span {
                        text-align: center;
                    }
                }
                >.datatable-cell.datatable-cell-sort {
                    cursor: pointer;
                    i {
                        font-size: .6rem;
                    }
                }
                >.datatable-cell.datatable-cell-resizing {
                    cursor: col-resize;
                }
                >.datatable-cell.datatable-cell-check {
                    text-align: left;
                    >span {
                        overflow: visible;
                        >.checkbox {
                            top: 2px;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
                >.datatable-cell.datatable-toggle-detail {
                    >span {
                        width: 12px;
                    }
                    >.datatable-toggle-detail {
                        display: inline-block;
                        text-align: right;
                        >i {
                            font-size: 1.4rem;
                            width: 12px;
                        }
                    }
                }
                >.datatable-cell.datatable-cell-sorted {
                    >span {
                        color: #1bc5bd;
                    }
                }
            }
            >.datatable-lock {
                border: 0;
            }
            >.datatable-lock.datatable-lock-left {
                z-index: 1;
                -webkit-box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
                box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
            }
            >.datatable-lock.datatable-lock-right {
                -webkit-box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
                box-shadow: 0 10px 17px 5px rgba(0,0,0,.075);
            }
            >.datatable-lock.datatable-lock-scroll {
                position: relative;
            }
        }
        >.datatable-body.ps {
            padding-bottom: 0;
        }
    }
    >.datatable-pager {
        margin: 0;
        padding: 1rem 0;
        padding-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        >.datatable-pager-nav {
            margin: 0;
            padding: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            >li {
                padding: 0;
                margin-right: 5px;
                display: inline-block;
                &:last-child {
                    margin-right: 0;
                }
                >.datatable-pager-link {
                    cursor: pointer;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    height: 2.25rem;
                    min-width: 2.25rem;
                    padding: .5rem;
                    -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                    position: relative;
                    font-size: .9rem;
                    line-height: 1rem;
                    font-weight: 500;
                    border-radius: .42rem;
                    color: #80808f;
                    >i {
                        font-size: .6rem;
                        text-align: center;
                        display: inline-block;
                        line-height: 0;
                        color: #b5b5c3;
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                    }
                    &:hover {
                        &:not(.datatable-pager-link-disabled) {
                            opacity: 1;
                            -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                            background-color: #1bc5bd;
                            color: #fff;
                            i {
                                -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                                transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                                transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                                transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                                color: #fff;
                            }
                        }
                    }
                }
                >.datatable-pager-link.datatable-pager-link-first {
                    background-color: #ecf1f6;
                }
                >.datatable-pager-link.datatable-pager-link-last {
                    background-color: #ecf1f6;
                }
                >.datatable-pager-link.datatable-pager-link-next {
                    background-color: #ecf1f6;
                }
                >.datatable-pager-link.datatable-pager-link-prev {
                    background-color: #ecf1f6;
                }
                >.datatable-pager-link.datatable-pager-link-disabled {
                    opacity: .6;
                    cursor: default;
                }
                >.datatable-pager-link.datatable-pager-link-active {
                    -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                    background-color: #1bc5bd;
                    color: #fff;
                    i {
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                        color: #fff;
                    }
                }
                >.datatable-pager-input {
                    height: 2.25rem;
                    width: 3.5rem;
                    text-align: center;
                    -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                    border-radius: .42rem !important;
                    background-color: #ecf1f6;
                    border-color: #ecf1f6;
                    color: #80808f;
                    &:focus {
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                        background-color: #ecf1f6;
                        border-color: #ecf1f6;
                        color: #80808f;
                    }
                }
            }
        }
        >.datatable-pager-info {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .datatable-pager-size {
                margin-right: 10px;
                .btn.dropdown-toggle {
                    height: 2.25rem;
                    padding: .45rem 1rem;
                    font-size: 1rem;
                    font-weight: 500;
                    border: 0 !important;
                    color: #80808f;
                    background-color: #ecf1f6 !important;
                    border-radius: .42rem;
                    .filter-option {
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                        color: #80808f;
                    }
                    i {
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                        color: #80808f;
                    }
                    &:after {
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                        color: #80808f;
                    }
                    &:focus {
                        border: 0 !important;
                        color: #fff !important;
                        background-color: #1bc5bd !important;
                        .filter-option {
                            -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                            color: #fff !important;
                        }
                        i {
                            -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                            color: #fff !important;
                        }
                        &:after {
                            -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                            color: #fff !important;
                        }
                    }
                    &:hover {
                        border: 0 !important;
                        color: #fff !important;
                        background-color: #1bc5bd !important;
                        .filter-option {
                            -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                            color: #fff !important;
                        }
                        i {
                            -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                            color: #fff !important;
                        }
                        &:after {
                            -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                            transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                            color: #fff !important;
                        }
                    }
                }
                .dropdown-menu.inner {
                    >li.selected {
                        >a {
                            span.check-mark {
                                margin-top: -.6rem;
                            }
                        }
                    }
                }
                .dropdown-menu {
                    z-index: 100;
                }
            }
            .datatable-pager-size.show {
                >.btn.dropdown-toggle {
                    border: 0 !important;
                    color: #fff !important;
                    background-color: #1bc5bd !important;
                    .filter-option {
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                        color: #fff !important;
                    }
                    i {
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                        color: #fff !important;
                    }
                    &:after {
                        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
                        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
                        color: #fff !important;
                    }
                }
            }
        }
    }
    >.datatable-pager.datatable-pager-top {
        margin-bottom: 10px;
    }
    .ps {
        >.ps__rail-x {
            >.ps__thumb-x {
                background-color: #ecf0f3;
                &:focus {
                    background-color: #ecf0f3;
                }
                &:hover {
                    background-color: #ecf0f3;
                }
            }
        }
        >.ps__rail-y {
            >.ps__thumb-y {
                background-color: #ecf0f3;
                &:focus {
                    background-color: #ecf0f3;
                }
                &:hover {
                    background-color: #ecf0f3;
                }
            }
        }
    }
}
.datatable.datatable-default.datatable-bordered {
    >.datatable-table {
        >.datatable-body {
            .datatable-row {
                border-bottom: 1px solid #ecf0f3;
                &:last-child {
                    border-bottom: 0;
                }
            }
            .datatable-row.datatable-row-subtable-expanded {
                border-bottom: 0;
            }
            tr {
                &:last-of-type {
                    border-bottom: 0;
                }
            }
            .datatable-row-detail {
                .datatable-detail {
                    table {
                        border-left: 1px solid #ecf0f3;
                        border-right: 1px solid #ecf0f3;
                    }
                }
            }
        }
        >.datatable-foot {
            .datatable-row {
                border-bottom: 1px solid #ecf0f3;
            }
            .datatable-row.datatable-row-subtable-expanded {
                border-bottom: 0;
            }
        }
        >.datatable-head {
            .datatable-row {
                border-bottom: 1px solid #ecf0f3;
            }
            .datatable-row.datatable-row-subtable-expanded {
                border-bottom: 0;
            }
        }
    }
}
.datatable.datatable-default.datatable-head-custom {
    >.datatable-table {
        >.datatable-foot {
            .datatable-row {
                >.datatable-cell {
                    >span {
                        color: #b5b5c3;
                        font-size: .9rem;
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: .1rem;
                    }
                }
                >.datatable-cell.datatable-cell-sorted {
                    >span {
                        color: #1bc5bd;
                    }
                }
            }
        }
        >.datatable-head {
            .datatable-row {
                >.datatable-cell {
                    >span {
                        color: #b5b5c3;
                        font-size: .9rem;
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: .1rem;
                    }
                }
                >.datatable-cell.datatable-cell-sorted {
                    >span {
                        color: #1bc5bd;
                    }
                }
            }
        }
    }
}
.datatable.datatable-default.datatable-head-bg {
    >.datatable-table {
        >.datatable-head {
            .datatable-row {
                border-bottom: 0;
                >.datatable-cell {
                    background-color: #f3f6f9;
                    &:first-child {
                        border-top-left-radius: .42rem;
                        border-bottom-left-radius: .42rem;
                    }
                    &:last-child {
                        border-top-right-radius: .42rem;
                        border-bottom-right-radius: .42rem;
                    }
                }
            }
        }
    }
}
.datatable.datatable-default.datatable-foot-bg {
    >.datatable-table {
        >.datatable-foot {
            .datatable-row {
                border-bottom: 0;
                >.datatable-cell {
                    background-color: #f3f6f9;
                    &:first-child {
                        border-top-left-radius: .42rem;
                        border-bottom-left-radius: .42rem;
                    }
                    &:last-child {
                        border-top-right-radius: .42rem;
                        border-bottom-right-radius: .42rem;
                    }
                }
            }
        }
    }
}
.datatable.datatable-default.datatable-error {
    .datatable-body {
        padding: 30px;
        text-align: center;
    }
}
.datatable.datatable-default.datatable-lock {
    >.datatable-table {
        >.datatable-body {
            >.datatable-lock {
                display: inline-block;
                position: relative;
                vertical-align: top;
                overflow: hidden;
            }
        }
        >.datatable-foot {
            >.datatable-lock {
                display: inline-block;
                position: relative;
                vertical-align: top;
                overflow: hidden;
            }
        }
        >.datatable-head {
            >.datatable-lock {
                display: inline-block;
                position: relative;
                vertical-align: top;
                overflow: hidden;
            }
        }
    }
}
.datatable.datatable-default.datatable-loaded {
    display: block;
    >.datatable-table {
        display: table;//block;
        >.datatable-body {
            visibility: visible;
            //display: block;
            position: relative;
            .datatable-row {
                /*display: table;
                table-layout: initial;*/
            }
        }
        >.datatable-foot {
            visibility: visible;
            display: block;
            position: relative;
            .datatable-row {
                /*display: table;
                table-layout: initial;*/
            }
        }
        >.datatable-head {
            visibility: visible;
            //display: block;
            position: relative;
            .datatable-row {
                /*display: table;
                table-layout: initial;*/
            }
        }
    }
}
.datatable.datatable-default.datatable-scroll {
    >.datatable-table {
        display: table;//block;
        >.datatable-foot {
            overflow: hidden;
            .datatable-row {
                position: relative;
            }
        }
        >.datatable-head {
            overflow: hidden;
            .datatable-row {
                position: relative;
            }
        }
        >.datatable-body {
            overflow: hidden;
        }
    }
}
.datatable.datatable-default.datatable-subtable {
    >.datatable-table {
        margin-bottom: 0;
        >.datatable-body {
            .datatable-toggle-subtable {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                >i {
                    line-height: 0;
                    font-size: 1.4rem;
                    color: #1bc5bd;
                }
                &:hover {
                    text-decoration: none;
                }
            }
            .datatable-row-subtable {
                display: table;
                width: 100%;
                border-top: 0;
                background-color: transparent;
                >.datatable-subtable {
                    >.datatable {
                        padding: 0 2.25rem;
                        -webkit-box-shadow: 0 10px 15px 1px rgba(0,0,0,.05);
                        box-shadow: 0 10px 15px 1px rgba(0,0,0,.05);
                        >.datatable-pager {
                            padding: 2.25rem 0;
                        }
                    }
                }
            }
            .datatable-row.datatable-row-subtable-expanded {
                >.datatable-cell {
                    background-color: #f3f6f9 !important;
                }
            }
            .datatable-row-subtable.datatable-row-loading {
                >.datatable-subtable {
                    >.datatable {
                        >.datatable-table {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
        >.datatable-foot {
            .datatable-toggle-subtable {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                >i {
                    line-height: 0;
                    font-size: 1.4rem;
                    color: #1bc5bd;
                }
                &:hover {
                    text-decoration: none;
                }
            }
            .datatable-row-subtable {
                display: table;
                width: 100%;
                border-top: 0;
                >.datatable-subtable {
                    >.datatable {
                        padding: 0 2.25rem;
                        >.datatable-pager {
                            padding: 2.25rem 0;
                        }
                    }
                }
            }
        }
        >.datatable-head {
            .datatable-toggle-subtable {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                >i {
                    line-height: 0;
                    font-size: 1.4rem;
                    color: #1bc5bd;
                }
                &:hover {
                    text-decoration: none;
                }
            }
            .datatable-row-subtable {
                display: table;
                width: 100%;
                border-top: 0;
                >.datatable-subtable {
                    >.datatable {
                        padding: 0 2.25rem;
                        >.datatable-pager {
                            padding: 2.25rem 0;
                        }
                    }
                }
            }
        }
    }
}
.modal {
    .datatable.datatable-default {
        >.datatable-pager {
            >.datatable-pager-info {
                .datatable-pager-size {
                    .dropdown-menu {
                        z-index: 1051;
                    }
                }
            }
        }
    }
}
.step-label {
    font-size:16px;
}
@media (max-width: 767px) {
    .datatable.datatable-default > .datatable-table.pages-table > .datatable-body .datatable-row > .datatable-cell {
        display: block;
        width: 100% !important;
        text-align: center;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .datatable.datatable-default > .datatable-table.pages-table > .datatable-body .datatable-row > .datatable-cell > span > a {
        word-break: break-word;
    }
    .datatable.datatable-default > .datatable-table.pages-table > .datatable-head .datatable-row > .datatable-cell
    {
        display: none;
    }
    .step-label {
        font-size: 14px;
    }
}
