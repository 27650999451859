.m-invoice-1 {
    .m-invoice__wrapper {
        overflow: hidden;
        .m-invoice__head {
            background-size: cover;
            background-repeat: no-repeat;
            .m-invoice__container {
                padding-left: 5rem;
                padding-right: 5rem;
                .m-invoice__logo {
                    display: table;
                    width: 100%;
                    padding-top: 10rem;
                    >a {
                        display: table-cell;
                        text-decoration: none;
                        >h1 {
                            font-weight: 600;
                            font-size: 2.7rem;
                            color: #fff;
                        }
                        &:last-child {
                            text-align: right;
                        }
                        &:first-child {
                            vertical-align: top;
                        }
                    }
                }
                .m-invoice__desc {
                    text-align: right;
                    display: block;
                    padding: 1rem 0 4rem 0;
                    color: #c3c1e9;
                    >span {
                        display: block;
                    }
                }
                .m-invoice__items {
                    display: table;
                    width: 100%;
                    padding: 5rem 0 6rem 0;
                    table-layout: fixed;
                    border-top: 1px solid #837dd1;
                    .m-invoice__item {
                        display: table-cell;
                        color: #fff;
                        .m-invoice__subtitle {
                            font-weight: 500;
                            padding-bottom: 0.5rem;
                        }
                        >span {
                            display: block;
                        }
                        .m-invoice__text {
                            color: #c3c1e9;
                        }
                    }
                }
            }
            .m-invoice__container.m-invoice__container--centered {
                width: 70%;
                margin: 0 auto;
                padding: 0;
            }
        }
        .m-invoice__body {
            padding: 6rem 5rem 0 5rem;
            table {
                thead {
                    tr {
                        th {
                            padding: 1rem 0 0.5rem 0;
                            border-top: none;
                            color: #898b96;
                            &:not(:first-child) {
                                text-align: right;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            padding: 1rem 0 1rem 0;
                            vertical-align: middle;
                            border-top: none;
                            font-weight: 600;
                            font-size: 1.1rem;
                            color: #6f727d;
                            &:not(:first-child) {
                                text-align: right;
                            }
                            &:last-child {
                                color: #FE21BE;
                            }
                        }
                        &:first-child {
                            td {
                                padding-top: 1.8rem;
                            }
                        }
                    }
                }
            }
        }
        .m-invoice__body.m-invoice__body--centered {
            width: 70%;
            margin: 0 auto;
            padding: 6rem 0 0 0;
        }
        .m-invoice__footer {
            margin-top: 7rem;
            background-color: #f7f8fa;
            .m-invoice__container {
                display: table;
                padding: 5rem 0 6rem 0;
                .m-invoice__content {
                    display: table-cell;
                    font-weight: 500;
                    .m-invoice__price {
                        font-size: 1.9rem;
                        font-weight: 600;
                        color: #FE21BE;
                    }
                    >span {
                        display: block;
                        display: table;
                        width: 100%;
                        padding-bottom: 1rem;
                        color: #3f4047;
                        >span {
                            display: table-cell;
                            &:last-child {
                                text-align: right;
                                font-size: 0.85rem;
                                color: #9699a2;
                            }
                        }
                        &:first-child {
                            color: #7b7e8a;
                        }
                    }
                    &:first-child {
                        width: 35%;
                    }
                    &:last-child {
                        text-align: right;
                    }
                    &:not(:first-child) {
                        >span {
                            &:last-child {
                                color: #9699a2;
                            }
                        }
                    }
                }
            }
            .m-invoice__container.m-invoice__container--centered {
                width: 70%;
                margin: 0 auto;
            }
        }
    }
}
.m-invoice-2.m-invoice-2--fit {
    .m-invoice__wrapper {
        .m-invoice__head {
            .m-invoice__container {
                padding: 0;
            }
            .m-invoice__container.m-invoice__container--centered {
                width: 100%;
            }
        }
        .m-invoice__body {
            padding: 2rem 0 0 0;
        }
        .m-invoice__body.m-invoice__body--centered {
            width: 100%;
        }
        .m-invoice__footer {
            .m-invoice__table {
                padding: 0;
            }
            .m-invoice__table.m-invoice__table--centered {
                width: 100%;
            }
        }
    }
}
.m-invoice-2 {
    .m-invoice__wrapper {
        .m-invoice__head {
            .m-invoice__container {
                padding: 0 5rem 0 5rem;
                .m-invoice__logo {
                    display: table;
                    width: 100%;
                    padding-top: 10rem;
                    >a {
                        display: table-cell;
                        text-decoration: none;
                        >h1 {
                            font-weight: 600;
                            font-size: 2.7rem;
                            color: #3f4047;
                        }
                        &:last-child {
                            text-align: right;
                        }
                        &:first-child {
                            vertical-align: top;
                        }
                    }
                }
                .m-invoice__desc {
                    text-align: right;
                    display: block;
                    padding: 1rem 0 4rem 0;
                    >span {
                        display: block;
                    }
                }
                .m-invoice__items {
                    display: table;
                    width: 100%;
                    padding: 6rem 0 3rem 0;
                    table-layout: fixed;
                    border-top: 1px solid #ebedf2;
                    .m-invoice__item {
                        display: table-cell;
                        .m-invoice__subtitle {
                            font-weight: 600;
                            padding-bottom: 0.5rem;
                        }
                        >span {
                            display: block;
                        }
                    }
                }
            }
            .m-invoice__container.m-invoice__container--centered {
                width: 70%;
                margin: 0 auto;
                padding: 0;
            }
        }
        .m-invoice__body {
            padding: 2rem 5rem 0 5rem;
            table {
                thead {
                    tr {
                        th {
                            padding: 1rem 0 0.5rem 0;
                            border-top: none;
                            color: #898b96;
                            &:not(:first-child) {
                                text-align: right;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            padding: 1rem 0 1rem 0;
                            vertical-align: middle;
                            border-top: none;
                            font-weight: 600;
                            font-size: 1.1rem;
                            color: #6f727d;
                            &:not(:first-child) {
                                text-align: right;
                            }
                        }
                        &:first-child {
                            td {
                                padding-top: 1.8rem;
                            }
                        }
                    }
                }
            }
        }
        .m-invoice__body.m-invoice__body--centered {
            width: 70%;
            margin: 0 auto;
            padding: 2rem 0 0 0;
        }
        .m-invoice__footer {
            margin-top: 9rem;
            padding: 5rem 0 7rem 0;
            background-color: #f7f8fa;
            .m-invoice__table {
                padding: 0 5rem 0 5rem;
                table {
                    thead {
                        tr {
                            th {
                                padding: 1rem 0 0.5rem 0;
                                border-top: none;
                                color: #898b96;
                                border-bottom: 1px solid #e2e5ec;
                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                padding: 1.8rem 0 0 0;
                                vertical-align: middle;
                                border-top: none;
                                font-weight: 600;
                                font-size: 1.1rem;
                                color: #6f727d;
                                &:last-child {
                                    text-align: right;
                                    font-size: 1.8rem;
                                    padding-top: 1rem;
                                }
                            }
                        }
                    }
                }
            }
            .m-invoice__table.m-invoice__table--centered {
                width: 70%;
                margin: 0 auto;
                padding: 0;
            }
        }
    }
}
@media (max-width: 768px) {
    .m-invoice-1 {
        .m-invoice__wrapper {
            .m-invoice__head {
                .m-invoice__container {
                    width: 100% !important;
                    padding: 0 2rem 0 2rem;
                    .m-invoice__logo {
                        padding-top: 4rem;
                        >a {
                            width: 100%;
                            display: block;
                            &:last-child {
                                padding-top: 1rem;
                                text-align: left;
                            }
                        }
                    }
                    .m-invoice__desc {
                        text-align: left;
                        padding-bottom: 2rem;
                    }
                    .m-invoice__items {
                        padding: 2rem 0 2rem 0;
                        .m-invoice__item {
                            width: 100%;
                            display: block;
                            padding-bottom: 2rem;
                            &:not(:first-child) {
                                padding-left: 0;
                            }
                        }
                    }
                }
                .m-invoice__container.m-invoice__container--centered {
                    padding: 0 2rem 0 2rem;
                }
            }
            .m-invoice__body {
                width: 100% !important;
                padding: 3rem 2rem 0 2rem;
                table {
                    thead {
                        tr {
                            th {
                                &:not(:first-child) {
                                    padding-right: 0.7rem;
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                &:not(:first-child) {
                                    padding-right: 0.7rem;
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
            .m-invoice__body.m-invoice__body--centered {
                padding: 3rem 2rem 0 2rem;
            }
            .m-invoice__footer {
                margin-top: 2rem;
                .m-invoice__container {
                    width: 100%;
                    padding: 4rem 2rem 4rem 2rem;
                    .m-invoice__content {
                        width: 100%;
                        display: block;
                        &:first-child {
                            width: 100%;
                        }
                        &:last-child {
                            padding-top: 2rem;
                            text-align: left;
                        }
                    }
                }
                .m-invoice__container.m-invoice__container--centered {
                    width: 100%;
                }
            }
        }
    }
    .m-invoice-2.m-invoice-2--fit {
        .m-invoice__wrapper {
            .m-invoice__head {
                .m-invoice__container {
                    padding: 0;
                }
                .m-invoice__container.m-invoice__container--centered {
                    width: 100%;
                    padding: 0;
                }
            }
            .m-invoice__body {
                padding: 2rem 0 0 0;
            }
            .m-invoice__body.m-invoice__body--centered {
                width: 100%;
                padding: 0;
            }
            .m-invoice__footer {
                padding: 3rem 0 4rem 0;
                .m-invoice__table.m-invoice__table--centered {
                    width: 100%;
                }
            }
        }
    }
    .m-invoice-2 {
        .m-invoice__wrapper {
            .m-invoice__head {
                .m-invoice__container {
                    width: 100% !important;
                    padding: 0 2rem 0 2rem;
                    .m-invoice__logo {
                        padding-top: 4rem;
                        >a {
                            width: 100%;
                            display: block;
                            &:last-child {
                                padding-top: 1rem;
                                text-align: left;
                            }
                        }
                    }
                    .m-invoice__desc {
                        text-align: left;
                        padding-bottom: 2rem;
                    }
                    .m-invoice__items {
                        padding: 2rem 0 2rem 0;
                        .m-invoice__item {
                            width: 100%;
                            display: block;
                            padding-bottom: 2rem;
                            &:not(:first-child) {
                                padding-left: 0;
                            }
                        }
                    }
                }
                .m-invoice__container.m-invoice__container--centered {
                    padding: 0 2rem 0 2rem;
                }
            }
            .m-invoice__body {
                width: 100% !important;
                padding: 0 2rem 0 2rem;
                table {
                    thead {
                        tr {
                            th {
                                &:not(:first-child) {
                                    padding-right: 1.5rem;
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                &:not(:first-child) {
                                    padding-right: 1.5rem;
                                    vertical-align: top;
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
            .m-invoice__body.m-invoice__body--centered {
                padding: 0 2rem 0 2rem;
            }
            .m-invoice__footer {
                margin-top: 4rem;
                padding: 3rem 2rem 4rem 2rem;
                .m-invoice__table.m-invoice__table--centered {
                    width: 100%;
                }
                .m-invoice__table {
                    table {
                        thead {
                            tr {
                                th {
                                    vertical-align: top;
                                    &:not(:first-child) {
                                        padding-left: 2rem;
                                    }
                                }
                            }
                        }
                        tbody {
                            tr {
                                td {
                                    vertical-align: top;
                                    &:not(:first-child) {
                                        padding-left: 2rem;
                                        vertical-align: top;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
