.ml-img-col {
    width: 400px;
    min-width: 300px;
    height: 230px;
    max-width: 100%;
    position: relative;
    overflow: hidden;

    img.ml-img {
        width: 100%;
        top:0;
        left:0;
        position: absolute;
        // max-width:340px;
    }

    .ml-category-label {
        font-size:16px;
        position: absolute;
        top:0;
        left:0;
        z-index: 1;
        padding: 6px 10px;
        color: #fff;
        background-color: rgb(0, 0, 0);
        min-width: 175px;
        max-width: 100%;
        text-align: center;
    }
}
table.ml-table {
    td {
        font-size: 17px;
        padding: 5px 15px;
    }
}
.table-layout-fixed {
    table-layout: fixed;
}
.watchlist_toggle {
    cursor: pointer;
}
.ml-btn-col {
    width: 400px;
    max-width: 100%;

    .ml-add-to-cart, .ml-make-an-offer {
        width: 250px;
        max-width: 100%;
        border-radius: 0;
        font-size: 24px;
        background-color: #ffbd3c;
        color: black;
    }
}
#ml-sort {
    max-width: 190px;
    height: 40px;
    appearance: none;
    background-color: transparent;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAKCAYAAAC5Sw6hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAOlJREFUeNpi/P//PwM1ABOImD59uvuuXbvkyTGgt7c3CG7QzZs3tUpLS2du3rxZgxRDvL29Zx44cMAVzAF5DYTb2toitbW190yePNkTJoYL79u3T9rExGRjZGRkJ0wMRcHcuXPttLS09pSVlWXhMmTJkiXmGhoa+8vLyzOQxTEUbt++XdHIyGhLSkpKHbpcZ2dnCMgQYLgEoMthtfXKlStMbm5uc11dXRfAxHJzc4sNDQ23rVmzRh+bHkZ80R8bG9v+9OlTWS4urq9AwN3a2ppnZWX1DqtiQgELcom/v/9UQuoYqZUgAQIMAHpgElRhIsCxAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center right;
    border: none;
    padding-right: 22px;
    text-align: center;
    text-align-last: center;
    overflow: hidden;
}
.ml-sort-label {
    margin-right: 10px;
    margin-bottom: 0;
}
.mv-type {
    line-height: 0;

    span {
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
        width: 39px;
        height:39px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right:15px;
    }

    span.active {
        text-decoration: none;
        cursor: default;
    }

    span.list {
        background-image: url(/media/icons/mv/list.png);
    }

    span.grid {
        background-image: url(/media/icons/mv/grid.png);
    }

    span.list.active {
        background-image: url(/media/icons/mv/list_active.png);
    }

    span.grid.active {
        background-image: url(/media/icons/mv/grid_active.png);
    }
}

.mv-grid {
    .ml-img-col {
        width: 100%;
        height:200px;
    }

    .ml-table-col {
        width: 100%;
        padding: 25px 0;
    }

    table.ml-table {
        max-width: 500px;
        margin: 0 auto;

        td {
            font-size: 18px;
            padding: 5px 5px;
        }
    }

    .ml-btn-col {
        width: 100%;
        box-shadow:0px 2px 4px 0px rgba(0, 0, 0, 0.20);

        .ml-add-to-cart, .ml-make-an-offer {
            width: 100%;
            font-size: 22px;
            background-color: #ffbd3c;
            color: black;
        }
    }

    .kt-portlet {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom:10px;
    }
}

.di-popup-inner {
    width: 320px;
    // height:320px;
    height:auto;
    // height: 425px;
    overflow-y:auto;
    padding: 10px;
}
.di-popup-basic-inner {
    width: 320px;
    // height:320px;
    height: auto;
    // height: 425px;
    overflow-y: auto;
    padding: 10px;
}

span.fa.fa-info-circle.et_ot_info_icon {
    font-size: 13px;
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
    left: 2px;
    padding-top: 1px;
    cursor:pointer;
    color:#555;
    line-height:12px;
}
table.ml-table#marketplace-table.table-layout-fixed-listrow td span.fa.fa-info-circle.et_ot_info_icon {
    top:3px;
}
table.ml-table#marketplace-table td {
    font-size: 16px !important;
    padding: 2px 10px !important;
}
table.ml-table#marketplace-table.table-layout-fixed-listrow td {
    font-size: 15px !important;
    padding: 2px 4px !important;
}

.marketplace-widget {
    .ml-img-col {
        height:80px;

        .ml-category-label {
            min-width: 135px;
            font-size: small;
        }
    }

    .ml-table-col {
        padding: 15px 0;
    }

    table.ml-table {
        td {
            font-size: 12px;
            padding: 5px 2px;
        }
    }
}
.mkt-list-row-height {
    height:140px;
}
.wlo_container_ml,
.marketplace_right_green {
    margin-left:10px;
}
.wlo_container_mr,
.marketplace_left_blue {
    margin-right:10px;
}
.wlo_container_mr.row {
    margin-right:0px;
}
@media screen and (max-width: 1024px) {
    .wlo_container_mr,
    .marketplace_left_blue {
        margin-right:0;
    }
    .wlo_container_ml,
    .marketplace_right_green {
        margin-left:0px;
    }
}

.countdown-container {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}
.countdown {
    display: flex;
    transform-style: preserve3d;
    // perspective: 500px;
    // height: 5rem;
    // width: 32rem;
    width:100%;
    margin: 0 auto;
}
.countdown.remove {
    animation: hide-countdown 1s cubic-bezier(0, 0.9, 0.56, 1.2) forwards;
    overflow: hidden;
} 
#js-countdown {
    max-width:225px;
}
.number, .separator {
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
}
.separator {
    margin: 0;
    width: 28px;
    font-size:28px;
    line-height:28px;
}
.new, .old, .current {
    position: absolute;
    border-radius: 1rem;
    height: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.new {
    animation: show-new 0.4s cubic-bezier(0, 0.9, 0.5, 1.2) forwards;
}
.old {
    animation: hide-old 2s cubic-bezier(0, 0.9, 0.56, 1.2) forwards;
} 
.countdown section {
    position: relative;
}
#js-days:after, #js-hours:after, #js-minutes:after, #js-seconds:after {
    color: #333;
    content: "DAYS";
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom:-28px;
    font-size:10px;
}
#js-hours:after {
    content: "HOURS";
}
#js-minutes:after {
    content: "MINUTES";
}
#js-seconds:after {
    content: "SECONDS";
}
@keyframes hide-countdown {
    to {
      height: 0;
      overflow: hidden;
    }
}
@keyframes show-new {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(-2rem) scale(0.8) rotateX(-20deg);
    }
    100% {
      transform: translate(-50%, -50%);
    }
}
@keyframes hide-old {
    0% {
      transform: translate(-50%, -50%);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(-5rem) scale(0.5) rotateX(-75deg);
    }
}


@media screen and (min-width: 1750px) {
    .wlo_col.col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
}
@media screen and (max-width: 1260px) {
    .wlo_col.col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media screen and (max-width: 1023px) {
    .wlo_col.col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .wlo_card_row {
        margin-top:40px;
    }
}
@media screen and (max-width: 768px) {
    .wlo_col.col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

// @media screen and (max-width: 1450px) {
//     .number, .separator {
//         height: 2.5rem;
//         font-size: 2.5rem;
//         line-height: 2.5rem;
//     }
//     .new, .old, .current {
//         height: 2.5rem;
//     }
//     #js-days:after, #js-hours:after, #js-minutes:after, #js-seconds:after {
//         font-size:12px;
//         bottom:-34px;
//     }
// }
// @media screen and (max-width: 1200px) {
//     .number, .separator {
//         height: 2.0rem;
//         font-size: 2.0rem;
//         line-height: 2.0rem;
//     }
//     .new, .old, .current {
//         height: 2.0rem;
//     }
//     #js-days:after, #js-hours:after, #js-minutes:after, #js-seconds:after {
//         font-size:10px;
//         bottom:-24px;
//     }
// }
