.marketplace-mobile {
    .mm-title {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 10px 0 20px;
    }

    .mm-subtext {
        margin: 10px 0;
    }

    .mm-steps-title {
        font-size: 1.2rem;
        font-weight: 500;
        margin:30px 0 5px;
    }

    .mm-item-list {
        margin-top: 30px;
    }

    .mm-guestpost-item {
        width: calc(100% - 150px);

        table {
            width: 100%;

            td, th {
                padding: 0 4px;
                vertical-align: top;
                text-align: center;
            }

            th {
                color:#b5b5c4;
                font-weight: normal;
                text-transform: uppercase;
            }

            td {
                color: #5468e5;
            }

            .gi-category {
                width: 120px;
                max-width: 100%;
            }
        }
    }

    .mm-pagination {
        margin-top:15px;

        .pagination {
            justify-content: center;
        }
    }

    @media (max-width: 575px) {
        .mm-guestpost-item {
            width: 100%;
            overflow-x: auto;

            table {
                margin-bottom: 15px;

                td, th {
                    padding: 0 2px;
                }
            }
        }
    }
}
