@media (min-width: 1900px) {
    .audit-main-widgets {
        .seo-errors {
            display: flex !important;
        }
    }
}

.notes-list {
    max-height: 345px;
    overflow-y: auto;
}

#analyticsGoogleUpdates {
    td {
        white-space: nowrap;
    }
}
