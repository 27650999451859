//
// Content
//



@include kt-desktop {
	// Content
	.kt-content {
		padding: kt-get($kt-page-padding, desktop) 0;

		// Content fit
		&.kt-content--fit-top {
			padding-top: 0;
		}

		&.kt-content--fit-bottom {
			padding-bottom: 0;
		}

		// Footer fixed
		.kt-footer--fixed & {
			padding-bottom: kt-get($kt-footer-config, self, fixed, height);
		} 
	}
	.mw-position-orders-area {
		margin:0 auto;
		max-width:540px;
		width:100%;
	}
	.mw-position-orders-area-wide {
		margin:0 auto;
		max-width:540px;
		width:100%;
	}
	.mw-position-orders-area-xl-wide {
		margin:0 auto;
		max-width:600px;
		width:100%;
	}

	.youve_selected_right_menu {
		max-width:500px;
		padding-left: 1.5rem !important;
	}
	.youve_selected_right_menu .card {
		// max-width:255px;
		box-shadow:0px 0px 3px 0px rgba(0, 0, 0, 0.20);
		padding:10px;
		border:0;
	}
	.add_on_modal_header_middle_inner {
		border-left:1px solid lightgray;
		position:relative;
		top:50px;
	}
}

@include kt-tablet-and-mobile {
	// Content
	.kt-content {
		padding: kt-get($kt-page-padding, mobile) 0;

		// Content fit
		&.kt-content--fit-top {
			padding-top: 0;
		}

		&.kt-content--fit-bottom {
			padding-bottom: 0;
		}

		// Subheader enabled
		.kt-subheader--enabled.kt-subheader--transparent & {
			padding-top: 0;
		}
	}
	.youve_selected_right_menu {
		padding-left: 0px;
		padding-right: 0px;
	}
	.youve_selected_right_menu .card {
		padding:10px;
		box-shadow:0px 0px 3px 0px rgba(0, 0, 0, 0.20);
		border:0;
	}
	.add_on_modal_header_middle_inner {
		padding-top:15px;
		padding-bottom:15px;
	}
}